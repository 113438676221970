import api from "@/EK-core/api";
import axiosIns from "@/libs/axios";
// import { getSearchResultOnRow } from "@Ekcore/util";
import router from "@/router";

export default {
    state: {
        lessondepartmentId: "",
        uploadProgress: 0,
        lesson: {
            lessons: [],
            count: 0,
        },
        paginationLesson: {
            pageSize: 10,
            pageIndex: 1,
            lessonType: "",
        },
        newFileUrl: "",
        url: "",
        thumbUrl: "",
        lessonDto: {
            name: "",
            isFree: false,
            lessonType: "Video",
            courseId: "",
            departmentId: "",
            fileId: "",
            description: "",
            subjectId:'',
            courseList:[]
        },
        lessonDetailsDto: {
            id: "",
            url: {
                id: "",
                url: "",
                thumbUrl: "",
            },
            order: 0,
            isFree: true,
            isHidden: false,
            name: "",
            description: "",
            semesterId: "",
            yearId: "",
            universityId: "",
            facultyId: "",
            departmentId: "",
            courseId: "",
            lessonType: "",
            question: [],
        },

  

    },
    mutations: {
        Get_Courses_By_Subject_Id(state, payload) {
            console.log("Adf",payload);
            state.lessonDto.courseList = payload
        },
        Get_Lessons(state, payload) {
            state.lesson = { ...payload };
        },
        Get_Lessons_List(state, payload) {
            state.lesson = { ...payload };
        },
        Add_Lesson(state, payload) {
            state.lesson.lessons.push(payload);
        },
        Add_Questions_Lessons(state, payload) {
            state.lessonDetailsDto.question.push(payload);
        },
        Reset_Lesson_Dto(state) {
            state.lessonDto = {
                name: "",
                isFree: false,
                lessonType: "Video",
                courseId: "",
                url: "",
                thumbUrl: "",
                description: "",
                courseList:[]
            };
        },

        Fill_Course_Select(state, payload) {
            state.lessonDto = {
                ...state.lessonDto,
                courseId: payload.id,
                subjectId:payload.subjectId,
                departmentId: payload.departmentId,
            };
        },
        Reset_Lesson(state) {
            state.lessonDto = {
                name: "",
                isFree: false,
                lessonType: "Video",
                courseId: "",
                departmentId: "",
                fileId: "",
                description: "",
                subjectId:'',
                courseList:[]

            };
        },
        Reset_LessonDetails(state) {
            state.newFileUrl = "";
            state.lessonDetailsDto = {
                id: "",
                url: {
                    id: "",
                    url: "",
                    thumbUrl: "",
                },
                order: 0,
                isFree: true,
                name: "",
                description: "",
                semesterId: "",
                yearId: "",
                universityId: "",
                facultyId: "",
                departmentId: "",
                courseId: "",
                lessonType: "",
                question: [],
            };
        },
        Add_File_Lesson(state, payload) {
            state.url = payload;
        },
        Add_Cover_File_Lesson(state, payload) {
            state.thumbUrl = payload;
        },
        SET_UPLOAD_PROGRESS(state, payload) {
            state.uploadProgress = payload;
        },
        Update_Lesson_File(state, payload) {
            state.lessonDetailsDto.url = { ...payload };
        },
        Get_Details_Lesson(state, payload) {
            state.lessonDetailsDto = { ...payload };
        },
        Update_Lesson(state, payload) {
            state.lessonDetailsDto = { ...payload };
        },
        Get_New_File_Link(state, payload) {
            state.newFileUrl = payload;
        },
        Delete_Lesson(state, id) {
            state.lesson.lessons.splice(
                state.lesson.lessons.findIndex((item) => item.id == id),
                1
            );
        },
        Delete_Range_Lesson(state, ids) {
            state.lesson.lessons = state.lesson.lessons.filter(
                (el) => !ids.includes(el.id)
            );
        },
    },

    actions: {
                getCourseBySubjectId({ commit }, subjectId) {
            return new Promise((resolve) => {
                api.get(`Course/Dash/GetNamesBySubjectId?subjectId=${subjectId}`, ({ data }) => {
                    commit("Get_Courses_By_Subject_Id", data);
                    resolve(data);
                });
            });
        },
        getLessons({ commit }, payload) {
            return new Promise((resolve) => {
                api.get(
                    `Lesson/Dash/GetAll?LessonType=${
                        payload.lessonType == "" ? "" : payload.lessonType
                    }&PageSize=${payload.pageSize}&PageIndex=${
                        payload.pageIndex
                    }`,
                    ({ data }) => {
                        commit("Get_Lessons", data);
                        resolve(data);
                    }
                );
            });
        },

        getFileLink({ commit }, payload) {
            return new Promise((resolve) => {
                api.get(`File/GetFileLink?url=${payload}`, ({ data }) => {
                    commit("Get_New_File_Link", data);
                    resolve(data);
                });
            });
        },
        addFileLesson({ commit }, payload) {
            const config = {
                onUploadProgress: function (progressEvent) {
                    let percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    commit("SET_UPLOAD_PROGRESS", percentCompleted);
                },
                noLoading: true,
            };
            return new Promise((resolve, reject) => {
                api.form.post(
                    "File/UploadLesson",
                    payload,
                    (res) => {
                        commit("Add_File_Lesson", res.data);
                        commit("Add_Cover_File_Lesson", res.data);
                        resolve(res.data);
                    },
                    {
                        success: "تم إضافة الملف بنجاح",
                        error: "فشل إضافة الملف",
                    },
                    (er) => {
                        reject(er);
                    },
                    config
                );
            });
        },
        updateLessonFile({ commit }, payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    onUploadProgress: function (progressEvent) {
                        let percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                        commit("SET_UPLOAD_PROGRESS", percentCompleted);
                    },
                    noLoading: true,
                }
                api.form.post(
                    "File/ModifyLesson",
                    payload,
                    ({ data }) => {
                        commit("Update_Lesson_File", data);
                        resolve(data);
                    },
                    {
                        success: "تم تعديل الدر=س  بنجاح",
                        error: "فشل تعديل الدرس ",
                    },
                    (er) => {
                        reject(er);
                    },
                    config
                );
            });
        },

        getLessonDetails({ commit }, id) {
            return new Promise((resolve) => {
                api.get(`Lesson/Dash/GetById?id=${id}`, ({ data }) => {
                    commit("Get_Details_Lesson", data);
                    resolve(data);
                });
            });
        },
        addLesson({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .post("Lesson/Dash/Add", payload)
                    .then((res) => {
                        resolve(res);
                        commit("Add_Lesson", res.data);
                    })
                    .catch((e) => {
                        reject(e);
                    });
            });
        },
        updateLesson({ commit }, payload) {
            api.post(
                "Lesson/Dash/Modify",
                payload,
                ({ data }) => {
                    commit("Update_Lesson", data);
                },
                {
                    success: "تم تعديل الدرس  بنجاح",
                    error: "فشل تعديل الدرس ",
                }
            );
        },

        deleteLesson({ commit }, ids) {
            api.delete(
                `Lesson/Dash/Delete`,
                () => {
                    commit("Delete_Lesson", ids);
                    router.go(-1);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف  الدرس",
                    success: "تم حذف الدرس بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة ",
                },
                ids
            );
        },
        deleteRangeLesson({ commit }, ids) {
            api.delete(
                `Lesson/Dash/Delete`,
                () => {
                    commit("Delete_Range_Lesson", ids);
                    // router.go(-1);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف  الدروس",
                    success: "تم حذف الدروس بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة ",
                },
                ids
            );
        },
    },
};
