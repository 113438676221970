import settings from "@admin/settings";
import notifications from "@admin/notifications";
import contactUs from "@admin/contactUs";
import dashboardUsers from "@admin/dashboardUsers";
import adverts from "@admin/adverts";
import subjects from "@admin/subjects";
import lessons from "@admin/lessons";
import courses from "@admin/courses";
import students from "@admin/students";
import sellPoints from "@admin/sellPoints";
import university from "@admin/university";
import faculty from "@admin/faculty";
import branches from "@admin/branches";
import codes from "@admin/codes";
import questionBank from "@admin/questionBank";
import home from "@admin/home";
import invoiceSellPoint from "@admin/invoiceSellPoint";
export default {
    codes,
    questionBank,
    home,
    university,
    faculty,
    branches,
    settings,
    sellPoints,
    notifications,
    dashboardUsers,
    adverts,
    lessons,
    subjects,
    contactUs,
    students,
    courses,
    invoiceSellPoint
};
