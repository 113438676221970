// Theme Colors
// Initially this will be blank. Later on when app is initialized we will assign bootstrap colors to this from CSS variables.
export const $themeColors = {
  primary: '#fff'
};

// App Breakpoints
// Initially this will be blank. Later on when app is initialized we will assign bootstrap breakpoints to this object from CSS variables.
export const $themeBreakpoints = {};

// APP CONFIG
export const $themeConfig = {
    app: {
        appName: "/appName.svg", // Will update name in navigation menu (Branding)
        // eslint-disable-next-line global-require
        // appLogoImage: "/favicon.ico", // Will update logo in navigation menu (Branding)
        appLogoImage: "", // Will update logo in navigation menu (Branding)
        domainPath: "https://cp.alresalah-app.com",
    },
    layout: {
        isRTL: true,
        skin: "light", // light, dark, bordered, semi-dark
        routerTransition: "zoom-fade", // zoom-fade, slide-fade, fade-bottom, fade, zoom-out, none
        type: "full", // vertical, horizontal
        contentWidth: "full", // full, boxed
        menu: {
            hidden: false,
            isCollapsed: true,
        },
        navbar: {
            // ? For horizontal menu, navbar type will work for navMenu type
            type: "floating", // static , sticky , floating, hidden
            backgroundColor: "", // BS color options [primary, success, etc]
        },
        footer: {
            type: "static", // static, sticky, hidden
        },
        customizer: true,
        enableScrollToTop: true,
    },
};
