import api from "@api";
import router from "@/router";
import swal from "sweetalert2";
export default {
    state: {
        departmentsSubjectsOptions: [],
        baseCoursesList: [],
        url: "",
        course: {
            courses: [],
            count: 0,
        },
        filterVal: "",
        filterDto: {
            semesterId: "",
            universityId: "",
            facultyId: "",
            yearId: "",
            pageSize: 4,
            pageIndex: 1,
        },
        courseDto: {
            name: "",
            url: "",
            description: "",
            isFree: false,
            subjectId: "",
            teacherId: "",
            departmentId: "",
        },
        courseDetailsDto: {
            id: "",
            name: "",
            url: "",
            description: "",
            isFree: false,
            isHidden: false,
            order: 1,
            lessonCount: 0,
            teacherId: "",
            semesterId: "",
            yearId: "",
            universityId: "",
            facultyId: "",
            departmentId: "",
            subjectId: "",
            lessons: [],
        },
        courseList: [],
    },
    mutations: {
        Get_Courses(state, payload) {
            state.course = { ...payload };
        },
        Get_Courses_List(state, payload) {
            state.course = { ...payload };
        },
        Add_Course(state, payload) {
            state.course.courses.push(payload);
        },
        Copy_Course(state, payload) {
            state.course.courses.push(payload);
        },
        Add_Lessons_Courses(state, payload) {
            state.courseDetailsDto.lessons.push(payload);
        },
        Filter_Lessons(state, payload) {
            state.filterVal = payload;
        },
        Reset_Course(state) {
            state.courseDto = {
                name: "",
                url: "",
                description: "",
                isFree: false,
                subjectId: "",
                teacherId: "",
                departmentId: "",
            };
        },
        Fill_Select_Course(state, payload) {
            state.courseDto = {
                ...state.courseDto,
                departmentId: payload.departmentId,
                subjectId: payload.id,
            };
        },
        SET_BASE_COURSE_LIST(state, payload) {
            state.baseCoursesList = payload;
        },
        Add_File_Course(state, payload) {
            state.url = payload;
        },
        Get_Details_Course(state, payload) {
            state.courseDetailsDto = { ...payload };
        },
        Update_Course(state, payload) {
            state.courseDetailsDto = { ...payload };
        },

        Delete_Course(state, id) {
            state.course.courses.splice(
                state.course.courses.findIndex((item) => item.id == id),
                1
            );
        },
        SET_DEPARTMENTS_OPTIONS: (state, payload) => {
            console.log(payload);
            state.departmentsSubjectsOptions = payload;
        },
    },
    getters: {
        allUniversities(state) {
            return state.departmentsSubjectsOptions;
        },
        filterLessons(state) {
            if (state.filterVal) {
                return state.courseDetailsDto.lessons.filter((ele) => {
                    return (
                        ele.name
                            .toLowerCase()
                            .includes(state.filterVal.toLowerCase()) ||
                        ele.lessonType
                            .toLowerCase()
                            .includes(state.filterVal.toLowerCase()) ||
                        ele.order
                            .toString()
                            .toLowerCase()
                            .includes(state.filterVal.toLowerCase())
                    );
                });
            } else return state.courseDetailsDto.lessons;
        },
    },
    actions: {
        getBaseCoursesList({ commit }) {
            api.get("Course/Dash/GetNames", ({ data }) => {
                commit("SET_BASE_COURSE_LIST", data);
            });
        },
        getCoursList({ commit }) {
            api.get(
                "Course/Dash/GetAll?PageIndex=1&PageSize=100000",
                ({ data }) => {
                    commit("Get_Courses_List", data);
                }
            );
        },
        getCourses(
            { commit },
            { universityId, facultyId, yearId, semesterId, pageIndex, pageSize }
        ) {
            api.get(
                `Course/Dash/GetAll?UniversityId=${
                    universityId === null ? "" : universityId
                }&FacultyId=${facultyId === null ? "" : facultyId}&YearId=${
                    yearId === null ? "" : yearId
                }&SemesterId=${
                    semesterId === null ? "" : semesterId
                }&PageSize=${pageSize}&PageIndex=${pageIndex} `,
                ({ data }) => {
                    commit("Get_Courses", data);
                    console.log(data);
                }
            );
        },
        addFileCourse({ commit }, payload) {
            return new Promise((resolve, reject) => {
                api.form.post(
                    "File/Upload",
                    payload,
                    (res) => {
                        commit("Add_File_Course", res.data);
                        resolve(res);
                    },
                    {
                        success: "تم إضافة الملف بنجاح",
                        error: "فشل إضافة الملف",
                    },
                    (er) => {
                        reject(er);
                    }
                );
            });
        },
        getCourseDetails({ commit }, id) {
            return new Promise((resolve) => {
                api.get(`Course/Dash/GetById?id=${id}`, ({ data }) => {
                    commit("Get_Details_Course", data);
                    resolve(data);
                });
            });
        },
        getCourseList({ commit }, subjectId) {
            return new Promise((resolve) => {
                api.get(
                    `Course/Dash/GetNamesBySubjectId?subjectId=${subjectId}`,
                    ({ data }) => {
                        commit("Get_Details_Course", data);
                        resolve(data);
                    }
                );
            });
        },
        addCourse({ commit }, payload) {
            return new Promise((resolve, reject) => {
                api.post(
                    "Course/Dash/Add",
                    payload,
                    (res) => {
                        commit("Add_Course", res.data);
                        resolve(res.data);
                        console.log(payload);
                        commit("Reset_Course");
                    },
                    {
                        success: "تم إضافة الكورس بنجاح",
                        error: "فشل إضافة الكورس",
                    },
                    (er) => {
                        reject(er);
                    }
                );
            });
        },
        copyCourse({ commit }, id) {
            api.post(
                `Course/Dash/Duplicate?id=${id}`,
                () => {
                    commit("Copy_Course", id);
                },
                {
                    confirm: " ?هل تريد بالتأكيد تكرار  الكورس",
                    success: "تم تكرار الكورس بنجاح",
                    error: "فشل التكرار ..يرجى اعادة المحاولة ",
                }
            );
        },
        updateCourse({ commit }, payload) {
            api.post(
                "Course/Dash/Modify",
                payload,
                ({ data }) => {
                    commit("Update_Course", data);
                    router.go(-1);
                },
                {
                    success: "تم تعديل الكورس  بنجاح",
                    error: "فشل تعديل الكورس ",
                },
                (er) => {
                    swal.fire({
                        icon: "error",
                        text: er.response.data,
                        title: "ترتيب الكورس خاطئ",
                    });
                }
            );
        },
        deleteCourse({ commit }, ids) {
            api.delete(
                `Course/Dash/Delete`,
                () => {
                    commit("Delete_Course", ids);
                    router.go(-1);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف  الكورس",
                    success: "تم حذف الكورس بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة ",
                },
                ids
            );
        },
        deletFileUrl(url) {
            return new Promise((resolve) => {
                api.delete(`File/Delete?path=${url}`, ({ data }) => {
                    //commit("Delete_File_Link", data);
                    resolve(data);
                });
            });
        },
        getDepartmentsWithSubjects({ commit }) {
            api.get("University/Dash/GetNamesWithSubjects", ({ data }) => {
                commit("SET_DEPARTMENTS_OPTIONS", data);
            });
        },
    },
};
